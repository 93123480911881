.error {
    background-color: #ffcccc;
    color: #be1000;
    padding: 10px;
    border: 1px solid #cc0000;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;

  }
  