.project-list {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
}
.project-list a {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit;
}
.project-list h4 {
  font-size: 0.9em;
  color: var(--heading-color);
}
.project-list p {
  color: var(--text-color);
  font-size: 0.9em;
}
.project-list .assigned-to {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}
.project-list ul {
  margin: 10px 0;
  display: flex;
}
.project-list li {
  margin-right: 10px;
}
.project-list .avatar {
  width: 30px;
  height: 30px;
}


.top-image-container {
  width: 100%;
  max-height: 0;
  padding-bottom: 30%; /* 2:1 aspect ratio, 50% = 1/2 */
  overflow: hidden;
  position: relative;
}

.top-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
  position: absolute;
  top: 0;
  left: 0;
}
