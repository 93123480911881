.sidebar {
  width: 300px;
  min-width: 300px;
  padding-top: 1rems;
  background: var(--primary-color);
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  color: #fff;
}
.sidebar-content {
  position: fixed;
  width: inherit;
}

.sidebar .links {
  margin-top: 80px;
  margin-left: 20px;
}
.sidebar .links li {
  margin-top: 10px;
}
.sidebar .links a {
  display: flex;
  padding: 10px;
  text-decoration: none;
  width: 100%;
  color: #000;
  box-sizing: border-box;
}
.sidebar .links img {
  margin-right: 10px;
  filter: invert(100%);
  color: #000;
}
.sidebar .links a.active {
  color: #555;
  background: var(--bg-color);
  border-radius: 20px 0 0 20px;
}
.sidebar .links  img {
  filter: invert(40%);
}

/* avatar in sidebar */
.sidebar .user .avatar {
  margin-bottom: 10px;
  width: 70px;
  height: 70px;
  border: 3px solid #fff;
}