
.top-image-container {
    width: 100%;
    max-height: 0;
    padding-bottom: 30%; /* 2:1 aspect ratio, 50% = 1/2 */
    overflow: hidden;
    position: relative;
  }
  
  .top-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
    position: absolute;
    top: 0;
    left: 0;
  }
  